// odkaz na generovani hashe hesla:
// https://passwordsgenerator.net/sha256-hash-generator/


const correctHashes = [
    'F5FC2D647F28D4E7B324B8B713E264E7A46644F0152CB690C334BC8537A7D887',
    'EAC4B71F26A24C757F40BF85ACCC6E34CB41333C2B719D37A12F8DE7E1D2299F',
    'A268F9F4F6508439DB3D94AFB51714C396BD176398A09A4B7227105EEE140D8B'
]

export default correctHashes
