import {Aktualita} from "../../src/interfaces/interfaces";

const aktuality : Aktualita[] = [
        {
                title: '',
                PDFtitle: '',
                PDF: ''
        },
     

]

export const aktualityImage  = "talentovky_2024.jpeg";         // jmeno souboru i s příponou, který vložíš do /obrazky/akuality

export default aktuality
